import Swiper, { Navigation, Pagination, Thumbs, EffectFade } from "swiper";
Swiper.use([Navigation, Pagination, Thumbs, EffectFade]);

function productSlider(): void {
  document.addEventListener("DOMContentLoaded", function() {
    var sliderThumbnail = new Swiper(".p-product__slider-thumbnail", {
      slidesPerView: 3,
      spaceBetween: 8,
      freeMode: true,
      watchSlidesProgress: true
    });

    //スライダー
    var slider = new Swiper(".p-product__slider-main", {
      effect: "fade",
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      thumbs: {
        swiper: sliderThumbnail
      }
    });
  });
}
//定期と単品タブ切り替え
function tabClick(): void {
  document.addEventListener("DOMContentLoaded", function() {
    const tabButtonEl = Array.prototype.slice.call(
      document.querySelectorAll(".js-tab-btn")
    );
    tabButtonEl.map(btn => {
      btn.addEventListener("click", () => {
        // 現在のタブを非表示に
        const selectTabButton = document.querySelector(
          ".js-tab-btn[aria-selected='true']"
        );
        const selectTabContent = document.querySelector(
          ".js-tab-content[aria-hidden='false']"
        );
        selectTabButton.setAttribute("aria-selected", "false");
        selectTabContent.setAttribute("aria-hidden", "true");
        selectTabContent.setAttribute("tabindex", "-1");
        // クリックしたタブを表示する
        btn.setAttribute("aria-selected", "true");
        const tab = document.getElementById(btn.getAttribute("aria-controls"));
        tab.setAttribute("aria-hidden", "false");
        tab.setAttribute("tabindex", "0");
      });
    });
  });
}
//選択した数量をaタグへ反映
function customOnChangeHandler(target): void {
  const quantitySelect = target.querySelector("select") as HTMLSelectElement;
  if (!quantitySelect) return;
  // `select`タグの親要素を取得
  const selectWrap = quantitySelect.closest(".p-product__form-select-wrap");
  const cartLink = selectWrap.nextElementSibling as HTMLAnchorElement;

  const selectedQuantity = quantitySelect.value;
  const hrefBase = cartLink.href.split("&quantity=")[0];
  cartLink.href = `${hrefBase}&quantity=${selectedQuantity}`;
}
//changeイベントでcustomOnChangeHandlerを実行
function addCustomOnChangeListener(target): void {
  const quantitySelect = target.querySelector("select") as HTMLSelectElement;
  if (!quantitySelect) return;

  quantitySelect.addEventListener("change", () =>
    customOnChangeHandler(target)
  );
}
//ecforce側の処理が完了したら対象のselectタグへ処理を実行
function observeSelectTagChanges(): void {
  document.addEventListener("DOMContentLoaded", () => {
    const target = document.querySelector(".js-mutation-target") as HTMLElement;
    const observer = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => {
        if (mutation.type === "childList" || mutation.type === "attributes") {
          addCustomOnChangeListener(target);
        }
      });
    });
    if (target) {
      observer.observe(target, {
        childList: true,
        subtree: true,
        attributes: true
      });
      addCustomOnChangeListener(target);
    }
  });
}

//jsonから取得したデータを元にselectタグを生成
function initializeProductSelection(): void {
  document.addEventListener("DOMContentLoaded", () => {
    const data = JSON.parse(
      document.querySelector("#product-relation-data")?.textContent ?? "{}"
    );
    if (data.single_id) {
      const quantity = Number(data.purchase_limit);
      const select = document.querySelector(
        ".js-quantity"
      ) as HTMLSelectElement;
      for (let i = 1; i <= quantity; i++) {
        const option = document.createElement("option");
        option.value = i.toString();
        option.textContent = i.toString() + "個";
        select.appendChild(option);
      }
      //hrefへvariantIdを追加
      if (!select) return;
      const variantId = data.single_id;
      const cartLink = select.nextElementSibling as HTMLAnchorElement;
      if (cartLink) {
        // `href`の中の`variant_id`の値を更新
        const url = new URL(cartLink.href);
        url.searchParams.set("variant_id", variantId);
        cartLink.href = url.toString();
      }
    }
  });
}
//定期商品の数量変更時にhrefへvariantIdを追加
function setupSingleProductChangeListener(): void {
  const target = document.querySelector(
    ".p-product__form-tab-panel.--single"
  ) as HTMLElement;
  addCustomOnChangeListener(target);
}

export {
  productSlider,
  tabClick,
  observeSelectTagChanges,
  initializeProductSelection,
  setupSingleProductChangeListener
};
