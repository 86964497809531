
  function commonHeaderMenuSp() :void {
    // document.addEventListener('DOMContentLoaded', () => {
    //   document.querySelectorAll('.header-menu-button, .header-menu-close-button').forEach(el => {
    //     el.addEventListener('click', () => {
    //       document.querySelectorAll('.header-menu-icon, .header-menu').forEach(el => {
    //         el.classList.toggle('open')
    //       })
    //     })
    //   })
    //   document.querySelectorAll('div > .header-menu-product-label').forEach(el => {
    //     el.addEventListener('click', () => {
    //       el.parentElement.classList.toggle('open')
    //     })
    //   })
    //   document.querySelector('#line-button')?.addEventListener('click', e => EcForce.Views.BaseView.prototype.setLineLoginToken(e))
    // })
  }
  
  function toggleHeaderNavVisibility(): void {
    // ターゲットとなる要素を取得します。
    const headerNav = document.querySelector('.header-nav');
    const headerNavFlex = document.querySelector('.header-nav-flex');
  
    // 現在のスクロール位置が100pxを超えているかどうかを判断します。
    if (window.scrollY > 100) {
      // 100pxを超えている場合、is-showクラスを付与します。
      headerNav?.classList.add('is-show');
      headerNavFlex?.classList.add('is-show');
    } else {
      // 100px未満の場合、is-showクラスを削除します。
      headerNav?.classList.remove('is-show');
      headerNavFlex?.classList.remove('is-show');
    }
  }
  
  // windowオブジェクトにスクロールイベントを登録します。
  // スクロールするたびにtoggleHeaderNavVisibility関数が呼び出されます。
  window.addEventListener('scroll', toggleHeaderNavVisibility);
  

  export { commonHeaderMenuSp, toggleHeaderNavVisibility };
