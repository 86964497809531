//bodyタグへ特定のクラスを付与
function commonAddBodyClassFromDataTemplate(): void {
  document.addEventListener("DOMContentLoaded", function() {
    const element = document.querySelector(
      ".js-add-body-class-target"
    ) as HTMLElement;
    if (element && element.dataset.template) {
      // data-templateの値を取得
      const templateValue = element.dataset.template;

      // bodyタグにクラスとして追加
      document.body.classList.add(templateValue);
    }
  });
}

export { commonAddBodyClassFromDataTemplate };
