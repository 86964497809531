/**
 * エントリーポイントファイル
 */

// 主要ライブラリインポート例
// import * as $ from "jquery";

// web-vitalsインポート

// Namespace（プロジェクト開始時には以下ファイルを適宜削除もしくは移動させて利用してください）
// Commonインポート
import { commonMenu } from "./namespace/common/commonMenu";
import { commonSmoothScroll } from "./namespace/common/commonSmoothScroll";
import { commonTargetBlank } from "./namespace/common/commonTargetBlank";
import { commonAddBodyClassFromDataTemplate } from "./namespace/common/commonAddBodyClassFromDataTemplate";
import {
  commonHeaderMenuSp,
  toggleHeaderNavVisibility
} from "./namespace/common/commonHeaderMenuSp";
// Topインポート
import {
  topAccordion,
  observeUnderLineElements,
  topProductHundler,
  topFeatureHundler,
  detailBlurScroll,
  circleScrollTrigger01,
  circleScrollTrigger02,
  openingAnimation,
  checkIfElementIsInViewport,
  activateFadeInOnScroll,
  activateTextShowOnScroll,
  handleScroll,
  onClickPlayVideo
} from "./namespace/top";
import {
  productSlider,
  tabClick,
  observeSelectTagChanges,
  initializeProductSelection,
  setupSingleProductChangeListener
} from "./namespace/product";

// Common処理実行
commonMenu();
// commonSmoothScroll();
commonTargetBlank();
// commonAddClassFromLastUrlSegment();
commonAddBodyClassFromDataTemplate();
commonHeaderMenuSp();
toggleHeaderNavVisibility();
// Top処理実行
topAccordion();
observeUnderLineElements();

openingAnimation();

if (document.getElementById("top")) {
  topProductHundler();
  topFeatureHundler();
  detailBlurScroll();
  circleScrollTrigger01();
  circleScrollTrigger02();
  checkIfElementIsInViewport();
  activateFadeInOnScroll();
  activateTextShowOnScroll();
  handleScroll();
  onClickPlayVideo();
}
if (document.getElementById("product-show-view")) {
  productSlider();
  tabClick();
  observeSelectTagChanges();
  initializeProductSelection();
  setupSingleProductChangeListener();
}

// web-vitalsコンソール出力 production or development
// const environment = process.env.NODE_ENV || "production";
