import Swiper , { Navigation, Pagination, Thumbs, EffectFade  } from "swiper";
Swiper.use([Navigation, Pagination, Thumbs, EffectFade]);
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";



/**
 * アコーディオンメニュー開閉
 */

function topAccordion(): void {
  const trigger = document.querySelectorAll(".js-accordion-trigger");
  const triggerArr = Array.prototype.slice.call(trigger);
  for (let i = 0; i < triggerArr.length; i++) {
    triggerArr[i].addEventListener("click", function() {
      const parent = this.parentNode;
      const body = parent.nextElementSibling;
      if (this.classList.contains("is-accordion-active")) {
        this.classList.remove("is-accordion-active");
        this.setAttribute("title", "メニューを開く");
        this.setAttribute("aria-selected", false);
        this.setAttribute("aria-expanded", false);
        body.classList.remove("is-accordion-open");
        body.setAttribute("aria-hidden", true);
        body.setAttribute("tabindex", -1);
      } else {
        this.classList.add("is-accordion-active");
        this.setAttribute("title", "メニューを閉じる");
        this.setAttribute("aria-selected", true);
        this.setAttribute("aria-expanded", true);
        body.classList.add("is-accordion-open");
        body.setAttribute("aria-hidden", false);
        body.setAttribute("tabindex", 0);
      }
    });
  }
}

// 要素がビューポートに半分入るのを検出するIntersectionObserverを設定する関数
function observeUnderLineElements(): void {
  // Intersection Observerのコールバック
  const observerCallback = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
    entries.forEach((entry) => {
      // isIntersectingは交差しているかどうかを真偽値で返す
      if (entry.isIntersecting) {
        entry.target.classList.add('is-active');
      } else {
        entry.target.classList.remove('is-active');
      }
    });
  };

  // IntersectionObserverのオプション
  const options = {
    threshold: 0.5 // 対象の50%が見えたらコールバックが呼ばれる
  };

  // IntersectionObserverのインスタンスを作成
  const observer = new IntersectionObserver(observerCallback, options);

  // .js-under-line クラスを持つ全ての要素を監視する
  const elements = document.querySelectorAll('.js-under-line');
  elements.forEach((element) => observer.observe(element));
}

// DOMContentLoadedイベントが発火したら監視を開始する
document.addEventListener('DOMContentLoaded', observeUnderLineElements);

function topProductHundler(): void {  
  document.addEventListener( 'DOMContentLoaded', function () {
    
    var sliderThumbnail = new Swiper('.p-top-products-thumbnail', {
      slidesPerView: 3,     
      spaceBetween: 8,
      freeMode: true,
      watchSlidesProgress: true,           
    });
    
    //スライダー
    var slider = new Swiper('.p-top-products-slider', {    
      effect: 'fade',
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      thumbs: {
        swiper: sliderThumbnail
      }
    });
 
  } );
}

function topFeatureHundler(): void {  
  // document.addEventListener( 'DOMContentLoaded', function () {    
  //   if (window.innerWidth <= 767) {
  //     new Swiper('.p-top-feature__slider', {    
  //       slidesPerView: 1.1,     
  //       spaceBetween: 15,                       
  //       pagination: {
  //         el: '.p-top-feature__swiper-pagination',
  //         clickable: true
  //       },
  //     });
  //   }
  // } );
}

// function detailBlurScroll(): void {
//   window.addEventListener('scroll', () => {
//     const image = document.querySelector('.p-top-detail__model-img img') as HTMLImageElement;
//     const pkgImage = document.querySelector('.p-top-detail__pkg-img') as HTMLElement;
//     const viewportHeight = window.innerHeight;
//     const viewportWidth = window.innerWidth;

//     if (image && pkgImage) {
//       const { top } = image.getBoundingClientRect();

//       // PCの場合は画面の縦幅の33%に達した時にボケ開始
//       // それ以外の場合は画面の半分に達した時にボケ開始
//       const blurStartPoint = viewportWidth > 768 ? viewportHeight * 1 : viewportHeight / 2;

//       // imageのボケ効果の計算
//       const blurValue = top < blurStartPoint ? Math.min(5, Math.max(0, 5 - (top / blurStartPoint) * 5)) : 0;
//       image.style.filter = `blur(${blurValue}px)`;

//       // pkgImageのボケ効果の計算（imageの逆）
//       const pkgBlurValue = 5 - blurValue; 
//       pkgImage.style.filter = `blur(${pkgBlurValue}px)`;

//       // pkgImageのtranslateYの計算
//       const translateY = -250 * (1 - blurValue / 5);
//       pkgImage.style.transform = `translateY(${translateY}px)`;
//     }
//   });
// }
function detailBlurScroll(): void {
  window.addEventListener('scroll', () => {
    const image = document.querySelector('.p-top-detail__model-img img') as HTMLImageElement;
    const pkgImage = document.querySelector('.p-top-detail__pkg-img') as HTMLElement;
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;

    if (image && pkgImage) {
      const { top } = image.getBoundingClientRect();

      // PCの場合は遅延を設定（例：画面の縦幅の50%に達した時にボケ開始）
      // スマートフォンの場合は画面の3分の1に達した時にボケ開始
      const blurStartPoint = viewportWidth > 768 ? viewportHeight * 0.5 : viewportHeight * 1 / 8;

      // imageのボケ効果の計算を調整
      const blurValue = top < blurStartPoint ? Math.min(5, Math.max(0, 5 - ((top - blurStartPoint * 0.2) / (blurStartPoint * 0.8)) * 5)) : 0;
      image.style.filter = `blur(${blurValue}px)`;

      // pkgImageのボケ効果の計算を調整（imageの逆）
      const pkgBlurValue = 5 - blurValue; 
      pkgImage.style.filter = `blur(${pkgBlurValue}px)`;      
    }
  });
}



// function circleScrollTrigger() {
//   gsap.registerPlugin(ScrollTrigger);

//   const isDesktop = window.matchMedia("(min-width: 768px)").matches;

//   gsap.set(".image-circle", { clipPath: isDesktop ? "circle(10% at 10% 30%)" : "circle(10% at 20% 10%)" });

//   // タイムラインを作成
//   const tl = gsap.timeline({
//     scrollTrigger: {
//       trigger: ".image-container-wrap",
//       start: "top 33%",
//       end: "bottom 90%",
//       scrub: 1,
//       markers: false,
//       onEnterBack: () => document.querySelector('.image-container').classList.add('is-filter-none'),
//       onLeave: () => document.querySelector('.image-container').classList.remove('is-filter-none')
//     }
//   });

//   // アニメーションの定義
//   if (isDesktop) {
//     tl.to(".image-circle", { clipPath: "circle(30% at 90% 60%)", ease: "none", duration: 20 }, 0)
//       .to(".image-circle", { clipPath: "circle(120% at 90% 60%)", ease: "none", duration: 10 }, ">");
//   } else {
//     tl.to(".image-circle", { clipPath: "circle(30% at 60% 60%)", ease: "none", duration: 20 }, 0)
//       .to(".image-circle", { clipPath: "circle(100% at 60% 60%)", ease: "none", duration: 20 }, ">");
//   }
// }

// function circleScrollTrigger() {
//   gsap.registerPlugin(ScrollTrigger);

//   const isDesktop = window.matchMedia("(min-width: 768px)").matches;

//   gsap.set(".image-circle", { clipPath: isDesktop ? "circle(10% at 10% 30%)" : "circle(10% at 20% 10%)" });

//   // タイムラインを作成
//   const tl = gsap.timeline({
//     scrollTrigger: {
//       trigger: ".image-container-wrap",
//       start: "top 33%",
//       end: "bottom 90%",
//       scrub: 1,
//       markers: false
//     }
//   });

//   // アニメーションの定義
//   if (isDesktop) {
//     tl.to(".image-circle", { clipPath: "circle(30% at 90% 60%)", ease: "none", duration: 20 }, 0)
//       .to(".image-circle", {
//         clipPath: "circle(120% at 90% 60%)",
//         ease: "none",
//         duration: 10,
//         onComplete: () => document.querySelector('.image-container').classList.add('is-filter-none')
//       }, ">");
//   } else {
//     tl.to(".image-circle", { clipPath: "circle(30% at 60% 60%)", ease: "none", duration: 20 }, 0)
//       .to(".image-circle", {
//         clipPath: "circle(100% at 60% 60%)",
//         ease: "none",
//         duration: 20,
//         onComplete: () => document.querySelector('.image-container').classList.add('is-filter-none')
//       }, ">");
//   }
// }
function circleScrollTrigger01() {
  gsap.registerPlugin(ScrollTrigger);

  const isDesktop = window.matchMedia("(min-width: 768px)").matches;

  gsap.set(".p-top-image-circle01", { clipPath: isDesktop ? "circle(20% at 10% 30%)" : "circle(20% at 20% 30%)" });

  // タイムラインを作成
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: ".p-top-image-container-wrap01",
      start: "top 67%",
      end: "bottom 50%",
      scrub: 1,
      markers: false
    }
  });

  // アニメーションの定義
  if (isDesktop) {
    tl.to(".p-top-image-circle01", { clipPath: "circle(30% at 70% 60%)", ease: "none", duration: 20 }, 0)
      .to(".p-top-image-circle01", {
        clipPath: "circle(120% at 90% 60%)",
        ease: "none",
        duration: 10,
        onComplete: () => document.querySelector('.p-top-image-container01').classList.add('is-filter-none')
      }, ">");
  } else {
    tl.to(".p-top-image-circle01", { clipPath: "circle(30% at 60% 60%)", ease: "none", duration: 200 }, 0)
      .to(".p-top-image-circle01", { clipPath: "circle(100% at 60% 60%)",ease: "none", duration: 200,
        onComplete: () => document.querySelector('.p-top-image-container01').classList.add('is-filter-none')
      }, ">");
  }
}

function circleScrollTrigger02() {
  gsap.registerPlugin(ScrollTrigger);

  const isDesktop = window.matchMedia("(min-width: 768px)").matches;

  gsap.set(".p-top-image-circle02", { clipPath: isDesktop ? "circle(20% at 20% 20%)" : "circle(20% at 30% 20%)" });

  // タイムラインを作成
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: ".p-top-image-container-wrap02",
      start: "top 67%",
      end: "bottom 70%",
      scrub: 1,
      markers: false
    }
  });

  // アニメーションの定義
  if (isDesktop) {
    tl.to(".p-top-image-circle02", { clipPath: "circle(30% at 70% 60%)", ease: "none", duration: 20 }, 0)
      .to(".p-top-image-circle02", {
        clipPath: "circle(120% at 90% 60%)",
        ease: "none",
        duration: 10,
        onComplete: () => document.querySelector('.p-top-image-container01').classList.add('is-filter-none')
      }, ">");
  } else {
    tl.to(".p-top-image-circle02", { clipPath: "circle(30% at 60% 60%)", ease: "none", duration: 200 }, 0)
      .to(".p-top-image-circle02", { clipPath: "circle(100% at 60% 60%)",ease: "none", duration: 200,
        onComplete: () => document.querySelector('.p-top-image-container01').classList.add('is-filter-none')
      }, ">");
  }
}








function openingAnimation() {
  window.addEventListener('DOMContentLoaded', () => {
    const webStorage = () => {
      const loading = document.querySelector(".loading");
      const loadingAnimation = document.querySelector(".loading-animation");
  
      if (sessionStorage.getItem('access')) {
        // 2回目以降アクセス時の処理
        loading?.classList.add('is-active');
      } else {
        // 初回アクセス時の処理
        sessionStorage.setItem('access', 'true'); // sessionStorageにデータを保存
        loadingAnimation?.classList.add('is-active'); // loadingアニメーションを表示
  
        setTimeout(() => {
          // ローディングを数秒後に非表示にする
          loading?.classList.add('is-active');
          loadingAnimation?.classList.remove('is-active');
        }, 2300); // ローディングを表示する時間
      }
    }
  
    webStorage();
  });
  
}

function checkIfElementIsInViewport() {
  const targets = document.querySelectorAll('.js-bgi-animation');

  targets.forEach(target => {
    const targetPosition = target.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    // 要素のトップがビューポートの下半分に入ったかチェック
    if (targetPosition.top < windowHeight / 2 && targetPosition.bottom >= 0) {
      target.classList.add('is-active');
    } else {
      target.classList.remove('is-active');
    }
  });
}

// スクロールイベントに関数をバインド
window.addEventListener('scroll', checkIfElementIsInViewport);

// ページロード時にも一度チェックを実行
document.addEventListener('DOMContentLoaded', () => {
  checkIfElementIsInViewport();
});

function activateFadeInOnScroll(): void {
  const fadeInElements = document.querySelectorAll('.js-pic-fade-in');

  // Define a function to get the appropriate viewport height based on device width
  function getViewportHeight() {
    const isPC = window.innerWidth >= 768; // Check if the device width is at least 768px
    return isPC ? window.innerHeight * 1 / 2 : window.innerHeight * 7 / 8;
  }

  window.addEventListener('scroll', () => {
    const viewportHeight = getViewportHeight(); // Get the current viewport height based on device width

    fadeInElements.forEach((elem, index) => {
      const rect = elem.getBoundingClientRect();     
      const elemVisible = rect.top < viewportHeight;

      if (elemVisible) {
        // 2番目と3番目の要素には遅延を設定
        if (index === 1 || index === 2) {
          setTimeout(() => {
            elem.classList.add('is-active');
          }, 150 * (index)); // 300msの遅延を追加
        } else {
          elem.classList.add('is-active');
        }
      }
    });
  });
}




function activateTextShowOnScroll(): void {
  const fadeTextElements = document.querySelectorAll('.js-fade-text');
  const viewportHeight = window.innerHeight;

  window.addEventListener('scroll', () => {
    fadeTextElements.forEach((elem) => {
      const rect = elem.getBoundingClientRect();
      const elemVisible = rect.top < (viewportHeight * 7 / 8);


      if (elemVisible) {
        const spans = elem.querySelectorAll('span');
        spans.forEach((span, index) => {
          setTimeout(() => {
            span.classList.add('is-show');
          }, 75 * index); // 各spanに100msずつの遅延を設定
        });
      }
    });
  });
}

function handleScroll() {
  document.addEventListener('DOMContentLoaded', () => {
    const floatInitElement = document.querySelector('.js-float-init');
    const footerInnerElement = document.querySelector('.l-footer__inner');
  
    const toggleShowClass = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const footerRect = footerInnerElement.getBoundingClientRect();
      const footerTop = footerRect.top + scrollTop;
  
      // 100px以上スクロールされているかどうかをチェック
      if (scrollTop > 100) {
        floatInitElement.classList.add('is-show');
      } else {
        floatInitElement.classList.remove('is-show');
      }
  
      // .l-footer__innerに接触しているかどうかをチェック
      if (scrollTop + window.innerHeight > footerTop) {
        floatInitElement.classList.remove('is-show');
      }
    };
  
    window.addEventListener('scroll', toggleShowClass);
  });
  
}

// window.addEventListener('scroll', handleScroll);


function onClickPlayVideo(): void {
  // クラスやIDのセレクターを定義
  const playVideoButtonId = 'js-play-video';
  const topIframeClass = 'p-top-iframe';
  const topIframeBgClass = 'p-top-iframe__bg';
  const isActiveClass = 'is-active';

  // ボタンとiframe要素、背景要素を取得
  const playVideoButton = document.getElementById(playVideoButtonId);
  const topIframe = document.querySelector(`.${topIframeClass}`) as HTMLElement;
  const topIframeBg = document.querySelector(`.${topIframeBgClass}`);

  // body要素のスクロール位置を保持する変数
  let scrollPosition = 0;

  // YouTube iframe要素を取得
  const youtubeIframe = document.querySelector('.p-top-iframe__content') as HTMLIFrameElement;

  // ビデオ再生ボタンのクリックイベント
  playVideoButton?.addEventListener('click', () => {
    if (topIframe) {
      topIframe.classList.add(isActiveClass);

      // YouTubeの自動再生を開始
      if (youtubeIframe) {
        youtubeIframe.src += "&autoplay=1";
      }
    }

    // 現在のスクロール位置を記録し、bodyにスクロール固定を適用
    scrollPosition = window.pageYOffset;
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollPosition}px`;
    document.body.style.width = '100%'; // 画面の幅を維持
  });

  // 背景クリックイベント
  topIframeBg?.addEventListener('click', () => {
    if (topIframe) {
      topIframe.classList.remove(isActiveClass);

      // YouTubeの自動再生を停止
      if (youtubeIframe) {
        youtubeIframe.src = youtubeIframe.src.replace("&autoplay=1", "");
      }
    }

    // bodyのスクロール固定を解除し、元の位置に戻す
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.width = ''; // 幅の設定を元に戻す
    window.scrollTo(0, scrollPosition);
  });
}


export { topAccordion, observeUnderLineElements, topProductHundler, topFeatureHundler, detailBlurScroll, circleScrollTrigger01, circleScrollTrigger02, openingAnimation, checkIfElementIsInViewport, activateFadeInOnScroll, activateTextShowOnScroll, handleScroll, onClickPlayVideo };
